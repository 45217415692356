@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
button {
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(#2b1055,#7597de); */

}

.container {
  min-height: 100vh;
  width: 100%;
  background-color: #485461;
  background-image: linear-gradient(135deg, #485461 0%, #28313b 74%); 
  overflow-x: hidden;
  transform-style: preserve-3d;


  background: linear-gradient(#2b1055,#7597de);
  overflow-x: hidden;

}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 3rem;
}

.menu {
  max-width: 72rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: sticky;
  
}

.logo {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 4rem;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.logo span {
  font-weight: 300;
}

.hamburger-menu {
  height: 4rem;
  width: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bar {
  width: 1.9rem;
  height: 1.5px;
  border-radius: 2px;
  background-color: #eee;
  transition: 0.5s;
  position: relative;
}

.bar:before,
.bar:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: #eee;
  transition: 0.5s;
}

.bar:before {
  transform: translateY(-9px);
}

.bar:after {
  transform: translateY(9px);
}

.main {
  position: relative;
  width: 100%;
  left: 0;
  z-index: 5;
  overflow: hidden;
  transform-origin: left;
  transform-style: preserve-3d;  
  transition: 0.5s;
  background: linear-gradient(#2b1055,#7597de);
}



/* header {
  min-height: 100vh;
  width: 100%;
  background: url("bg.jpg") no-repeat top center / cover;
  position: relative;
} */

/* .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(43, 51, 59, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .inner {
  max-width: 35rem;
  text-align: center;
  color: #fff;
  padding: 0 2rem;
} */

.title {
  font-size: 2.7rem;
}

/* .btn {
  margin-top: 1rem;
  padding: 0.6rem 1.8rem;
  background-color: #1179e7;
  border: none;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
} */

.container.active .bar {
  transform: rotate(360deg);
  background-color: transparent;
}

.container.active .bar:before {
  transform: translateY(0) rotate(45deg);
}

.container.active .bar:after {
  transform: translateY(0) rotate(-45deg);
}



.container.active .main {
  animation: main-animation 0.5s ease;
  cursor: pointer;
  transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);

}



@keyframes main-animation {
  from {
    transform: translate(0);
  }

  to {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }
}

.links {
  position: absolute;
  width: 30%;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
}

.links a {
  text-decoration: none;
  color: #eee;
  padding: 0.7rem 0;
  display: inline-block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(10px);
  animation: hide 0.5s forwards ease;
}

.links a:hover {
  color: #fff;
}

.container.active .links a {
  animation: appear 0.5s forwards ease var(--i);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transform-origin: left;
  transition: 0.5s;
  background-color: white;
}

.shadow.one {
  z-index: -1;
  opacity: 0.15;
}

.shadow.two {
  z-index: -2;
  opacity: 0.1;
}

.container.active .shadow.one {
  animation: shadow-one 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
}

@keyframes shadow-one {
  0% {
    transform: translate(0);
  }

  5% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }

  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
  }
}

.container.active .shadow.two {
  animation: shadow-two 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
}

@keyframes shadow-two {
  0% {
    transform: translate(0);
  }

  20% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }

  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
  }
}

.container.active .main:hover + .shadow.one {
  transform: perspective(1300px) rotateY(20deg) translateZ(230px) scale(0.5);
}

.container.active .main:hover {
  transform: perspective(1300px) rotateY(20deg) translateZ(340px) scale(0.5);
}




section{
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

}
section::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top,#1c0522,transparent);
  z-index: 1000;

}
section img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
section img#moon{
  mix-blend-mode:screen;
}

section img#comet{
  mix-blend-mode:screen;
  
  width: 100%;
  height: auto;
  
}

section img#mountains_front{
  z-index: 10;

}

#text{
  position: absolute;
  right: 0px;
  top: 120px;
  color: transparent;
  background-clip: text;
  background-color: linear-gradient(#2b1055,#7597de);
    -webkit-background-clip: text;
    -moz-background-clip: text;
  white-space: nowrap;
  font-size: 4.5vw;
  z-index: 9;
  transform: translateY(100px);
  text-shadow:rgba(255,255,255,0.5) 0px 3px 3px;
}

.sec{
  position: relative;
  padding: 100px;
  background: #1c0522;
}

.sec h2{
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  
  
}

h3{
  color: #fff;
  font-size: 2em;
 text-align: center;
}

.sec p{
  font-size: 1em;
  color: #fff;
}

.social {
  padding: 0;
  box-sizing: border-box;
  margin-left: 70%;
  margin-top: 15px;
  color: #fff;
  
  
  display: flex;
}

.social li{
  position: relative;
  list-style: none;
  margin: 0 10px;
  cursor: pointer;
}

.social li a {
  text-decoration: none;
}

.social li a .fa-brands {
font-size: 2.5em;
color: slategray;
}

.social li::before{
  font-family: fontAwesome;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.5em;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;

}

.social li:nth-child(1):before{
content: '\f08c';
color: #0e76a8;
border-bottom: 4px solid #0e76a8;
pointer-events: none;
}

.social li:nth-child(2):before{
  content: '\f16d';
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
-webkit-background-clip: text;
/* Also define standard property for compatibility */
background-clip: text;
-webkit-text-fill-color: transparent;
  color:  radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  border-bottom: 4px solid orange;
  pointer-events: none;
  }
  

.social li:hover::before{
  height: 100%;
}


